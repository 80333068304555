<template>
  <div class="SaleAdd">

    <el-form ref="form" label-width="100px" :model="form" :rules="rules" class="SaleAdd-form" :hide-required-asterisk="true" @submit.native.prevent  >

      <div>
        <div class="SaleAdd-form-title">
          产品信息
        </div>

        <div class="SaleAdd-form-item">
          <el-form-item label="实体卡号" prop="cardNo">
            <el-input v-model="form.cardNo" @change="cardNoChange" clearable placeholder="请输入实体卡号"></el-input>
          </el-form-item>
          <el-form-item label="产品类别" prop="productCategory">
            <span class="SaleAdd-form-item-text">{{ form.productCategory ? $store.state.category.filter(item => { return item.dictKey == form.productCategory })[0].dictValue : "- -" }}</span>
          </el-form-item>
          <el-form-item label="产品名称" prop="productName">
            <span class="SaleAdd-form-item-text">{{ form.productName ? form.productName : "- -" }}</span>
          </el-form-item>
          <el-form-item label="产品价格" prop="price">
            <span class="SaleAdd-form-item-text">{{ form.price ? "￥" + form.price : "- -" }}</span>
          </el-form-item>
          <el-form-item label="实际购买价格" prop="actualPrice">
            <el-input v-model="form.actualPrice" clearable placeholder="请输入实际购买价格"></el-input>
          </el-form-item>
          <el-form-item label="产品介绍" prop="productDesc">
            <span class="SaleAdd-form-item-text">{{ form.productDesc ? form.productDesc : "- -" }}</span>
          </el-form-item>
        </div>

        <div class="SaleAdd-form-title">
          用户信息
        </div>

        <div class="SaleAdd-form-item">
          <el-form-item label="购买人姓名" prop="buyPersonName">
            <el-input v-model="form.buyPersonName" clearable placeholder="请输入购买人姓名"></el-input>
          </el-form-item>
          <el-form-item label="购买人手机号" prop="buyPersonPhone">
            <el-input v-model="form.buyPersonPhone" clearable placeholder="请输入购买人手机号"></el-input>
          </el-form-item>
        </div>
      </div>

      <el-form-item>
        <div class="SaleAdd-form-button">
          <el-button @click="cancel" size="small">取消</el-button>
          <el-button @click="onSubmit(form)" type="primary" size="small">保存</el-button>
        </div>
      </el-form-item>

    </el-form>


  </div>
</template>

<script>
export default {
  name: "SaleAdd",
  data() {
    var validatorActualPrice = (rule, value, callback) => {
      if(value < 0) {
        callback(new Error("实体卡价格不能小于0"));
      }else {
        callback();
      }
    };
    return {
      form: {
        cardNo: null, // 实体卡号
        price: null, // 产品价格
        productCategory: null, // 产品类别
        productDesc: null, // 产品描述
        productName: null, // 产品名称
        actualPrice: null, // 实际购买价格
        buyPersonName: null, // 购买人姓名
        buyPersonPhone: null, // 购买人手机号
      },
      rules: {
        cardNo: [
            { required: true, message: '请输入实体卡号', trigger: 'blur' },
        ],
        actualPrice: [
            { required: true, message: '请输入实际购买价格', trigger: 'blur' },
            { pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '实际购买价格不正确' },
            { validator: validatorActualPrice, trigger: 'change' }
        ],
        buyPersonName: [
          { required: true, message: '请输入购买人姓名', trigger: 'blur' },
        ],
        buyPersonPhone: [
          { required: true, message: '请输入购买人手机号', trigger: 'blur' },
          { pattern: /^((13[0-9])|(14[0,1,4-9])|(15[0-3,5-9])|(16[2,5,6,7])|(17[0-8])|(18[0-9])|(19[0-3,5-9]))\d{8}$/, message: '购买人手机号不正确', trigger: 'blur' },
        ]
      }
    }
  },
  created() {

  },
  methods: {
    onSubmit(y) {
      console.log(y);
      this.$refs.form.validate((valid) => {
        if(valid) {
          console.log(y);

          this.$post("sale",y)
            .then(res => {
              if(res) {
                console.log(res);

                this.$message.success(res.message);
                this.$router.go(-1);

              }
            })

        }
      })

    },
    cancel() {
      this.$router.go(-1);
    },
    cardNoChange(e) {

      if(e) {
        this.$get("sale/returnInfo",e,true)
            .then(res => {
              if(res) {
                this.form.price= res.data.price;
                this.form.productCategory= res.data.productCategory;
                this.form.productDesc= res.data.productDesc;
                this.form.productName= res.data.productName;

              }else {
                this.form.price= null;
                this.form.productCategory= null;
                this.form.productDesc= null;
                this.form.productName= null;
              }
            })
      }

    }
  }
}
</script>

<style lang="scss" scoped>
 @import "../../style/Sale/SaleAdd";
</style>
